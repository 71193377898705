import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { CopyIcon } from 'react-line-awesome';
import { copyToClipboard } from 'utils/clipboard';
import { AppDispatch } from 'stores/store';
import { useDispatch } from 'react-redux';

type ReadOnlyTextFieldProps = {
  value?: string;
  label?: string;
  className?: string;
};

const ReadOnlyTextField: React.FC<ReadOnlyTextFieldProps> = ({
  value,
  label,
  className,
}: ReadOnlyTextFieldProps) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="copy bank name to clipboard"
              onClick={copyToClipboard(dispatch, value)}
            >
              <CopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ReadOnlyTextField;
