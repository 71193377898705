import { CircularProgress } from "@material-ui/core";
import NoAppBarBase from "./NoAppBarBase";

const Splash: React.FC = () => {
  return (
    <NoAppBarBase>
      <CircularProgress />
    </NoAppBarBase>
  );
};

export default Splash;
