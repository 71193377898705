/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "pages/auth/LoginPage";
import InvalidRoutePage from "pages/InvalidRoutePage";
import SettingsPage from "pages/settings/SettingsPage";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "stores/auth/selectors";
import Splash from "components/Splash";
import GamesPage from "pages/games/GamesPage";
import AddGamePage from "pages/games/AddGamePage";
import { AppDispatch } from "stores/store";
import { initGames } from "stores/game/slice";
import { selectGamesState } from "stores/game/selectors";
import LogoutPage from "pages/auth/LogoutPage";

function App(): React.ReactElement {
  const authState = useSelector(selectAuthState);
  const gameState = useSelector(selectGamesState);

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (!gameState.isLoaded && authState.isLoggedIn) {
      dispatch(initGames);
    }
  }, [dispatch, gameState.isLoaded, authState.isLoggedIn]);

  if (!authState.isInitialized) {
    return <Splash />;
  }

  return (
    <Switch>
      <Route path="/" exact>
        {authState.isLoggedIn ? (
          <Redirect to="/games" />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
      <Route path="/login">
        {authState.isLoggedIn ? <Redirect to="/" /> : <LoginPage />}
      </Route>
      <Route path="/games/add">
        <AddGamePage />
      </Route>
      <Route path="/games">
        <GamesPage />
      </Route>
      <Route path="/settings">
        <SettingsPage />
      </Route>
      <Route path="/logout">
        <LogoutPage />
      </Route>
      <Route>
        <InvalidRoutePage />
      </Route>
    </Switch>
  );
}

export default App;
