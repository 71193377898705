import React, { useState } from "react";
import {
  ListItem,
  List,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  Fab,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { EditIcon, PlusIcon, ShareIcon } from "react-line-awesome";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectGamesState } from "stores/game/selectors";
import dayjs from "dayjs";
import Page from "components/PageBase";
import { GameSecret } from "stores/game/types";
import ReadOnlyTextField from "components/ReadOnlyTextField";

type DialogState = { game?: GameSecret; includeSecret?: boolean };

const defaultDialogState: DialogState = { includeSecret: true };

const GamesPage: React.FC = () => {
  const gameState = useSelector(selectGamesState);
  const history = useHistory();
  const [state, setState] = useState<DialogState>(defaultDialogState);

  const onShareClick = (game: GameSecret) => {
    setState({ game, includeSecret: true });
  };

  const onDialogClose = () => {
    setState(defaultDialogState);
  };

  const onEditClick = (game: GameSecret) => {
    history.push("/games/add", game);
  };

  return (
    <Page title="Games">
      <Helmet>
        <title>Admin: Upcoming Games</title>
      </Helmet>
      <List
        subheader={<ListSubheader>Upcoming games</ListSubheader>}
        style={{ paddingBottom: "7em" }}
      >
        {gameState &&
          gameState.items.map((game) => {
            const startAt = dayjs(game.startAt);
            return (
              <ListItem key={game.id}>
                <ListItemText
                  primary={`${game.name} <${game.repEmail}>`}
                  secondary={`${startAt.format(
                    "ddd Do MMMM YYYY h:mm a"
                  )} (${startAt.fromNow()})`}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onShareClick(game)}>
                    <ShareIcon />
                  </IconButton>
                  <IconButton onClick={() => onEditClick(game)}>
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
      <Fab
        color="primary"
        aria-label="add"
        component={RouterLink}
        to="/games/add"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        <PlusIcon />
      </Fab>
      <Dialog
        onClose={onDialogClose}
        aria-labelledby="simple-dialog-title"
        open={!!state.game}
      >
        <DialogTitle id="share-link-dialog">Share</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This is the link to share with the players.
          </DialogContentText>
          <ReadOnlyTextField
            value={`${process.env.REACT_APP_GAME_URL}/?id=${state.game?.id}${
              state.includeSecret ? `&s=${state.game?.password}` : ""
            }`}
          />
          <FormControlLabel
            label="Include password"
            control={
              <Checkbox
                checked={!!state.includeSecret}
                onChange={(event, checked) =>
                  setState({ ...state, includeSecret: checked })
                }
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default GamesPage;
