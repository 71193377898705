import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import Page from 'components/PageBase';
import { useHistory } from 'react-router-dom';
import { logout } from 'stores/auth/slice';
import { AppDispatch } from 'stores/store';
import { useDispatch } from 'react-redux';

const LogoutPage: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const onCancelClick = async () => {
    history.goBack();
  };
  
  const onLogoutClick = async () => {
    const action = await dispatch(logout());
    if (logout.fulfilled.match(action)) {
      history.replace('/login');
    }
  };

  return (
    <Page title="Sign out">
      <Dialog
        open
        onClose={onCancelClick}
        aria-labelledby="default-currency-dialog-title"
      >
        <DialogTitle id="default-currency-dialog-title">
          Really sign out?
        </DialogTitle>
        <DialogContent>
          <Typography>
            Your session on this device will be terminated.
          </Typography>
          <Typography variant="body2">
            Sessions on other devices will remain active.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onLogoutClick}>
            Sign out
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default LogoutPage;
