import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyAsyncThunkConfig } from "stores/types";
import {
  AddGameData,
  GameSecret,
  GamesState,
  UpdateGameData,
} from "./types";
import firebase from "firebase";
import dayjs from "dayjs";
import store from "stores/store";
import { logout } from "stores/auth/slice";
import { AuthState } from "stores/auth/types";
import { secretsCol, gamesCol } from "firestore";

let cancelGameListener: () => void;

export const addGame = createAsyncThunk<
  GameSecret,
  AddGameData,
  MyAsyncThunkConfig
>("ADD_GAME", async (opts, { rejectWithValue, getState }) => {
  try {
    const secretDoc = secretsCol.doc();
    const { authState } = getState() as { authState: AuthState };
    const secret = {
      name: opts.name,
      password: opts.password,
      repPassword: opts.repPassword,
      startAt: opts.startAt,
      repEmail: authState?.user?.email || "",
    };
    await secretDoc.set({ ...secret, startAt: dayjs(secret.startAt).utc().toDate() });
    return {
      ...secret,
      id: secretDoc.id,
    };
  } catch (err) {
    return rejectWithValue({
      code: err.code,
      message: err.message,
    });
  }
});

export const updateGame = createAsyncThunk<
  undefined,
  UpdateGameData,
  MyAsyncThunkConfig
>("UPDATE_GAME", async (opts, { rejectWithValue }) => {
  try {
    const secretDoc = secretsCol.doc(opts.id);
    const game = {
      name: opts.name,
      password: opts.password,
      repPassword: opts.repPassword,
      startAt: opts.startAt,
    };
    await secretDoc.update({ ...game, startAt: dayjs(game.startAt).utc().toDate() });
  } catch (err) {
    return rejectWithValue({
      code: err.code,
      message: err.message,
    });
  }
});

export const deleteGame = createAsyncThunk<
  undefined,
  string,
  MyAsyncThunkConfig
>("DELETE_GAME", async (id, { rejectWithValue }) => {
  try {
    await secretsCol.doc(id).delete();
    await gamesCol.doc(id).delete();
  } catch (err) {
    return rejectWithValue({
      code: err.code,
      message: err.message,
    });
  }
});

const initialState: GamesState = {
  items: [],
};

const slice = createSlice({
  name: "games",
  initialState,
  reducers: {
    receiveGames: (state, action: PayloadAction<GameSecret[]>) => {
      state.isLoaded = true;
      state.items = action.payload;
    },
  },
  extraReducers: (b) =>
    b.addCase(logout.fulfilled, () => {
      cancelGameListener && cancelGameListener();
      return initialState;
    }),
});

export const initGames = () => {
  cancelGameListener = secretsCol
    .where("startAt", ">=", dayjs().subtract(2, "hour").toDate())
    .orderBy("startAt", "asc")
    .onSnapshot((snap) => {
      const games: GameSecret[] = [];
      snap.forEach((doc) => {
        const d = doc.data();
        games.push({
          id: doc.id,
          name: d.name,
          password: d.password,
          repPassword: d.repPassword,
          repEmail: d.repEmail,
          startAt: dayjs(
            (d.startAt as firebase.firestore.Timestamp).toDate()
          ).valueOf(),
        });
      });
      store.dispatch(slice.actions.receiveGames(games));
    });
};

export default slice.reducer;
