import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, LoginData, User } from "stores/auth/types";
import firebase from "firebase/app";
import "firebase/auth";
import store from "stores/store";
import { MyAsyncThunkConfig } from "stores/types";

export const login = createAsyncThunk<undefined, LoginData, MyAsyncThunkConfig>(
  "LOGIN",
  async (opts, { rejectWithValue }) => {
    try {
      const auth = firebase.auth();
      await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      await auth.signInWithEmailAndPassword(opts.email, opts.password);
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const logout = createAsyncThunk<
  undefined,
  undefined,
  MyAsyncThunkConfig
>("LOGOUT", async (opts, { rejectWithValue }) => {
  try {
    await firebase.auth().signOut();
  } catch (err) {
    return rejectWithValue({
      code: err.code,
      message: err.message,
    });
  }
});

const initialState: AuthState = {};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    receiveUser: (state, action: PayloadAction<User | undefined>) => {
      state.isInitialized = true;
      state.isLoggedIn = !!action.payload;
      state.user = action.payload;
    },
  },
  extraReducers: (b) =>
    b.addCase(logout.fulfilled, () => ({
      isInitialized: true,
      isLoggedIn: false,
    })),
});

export const initAuth = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      store.dispatch(
        slice.actions.receiveUser({
          email: user.email ?? "",
        })
      );
    } else {
      store.dispatch(slice.actions.receiveUser());
    }
  });
};

export default slice.reducer;
