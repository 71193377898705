import React from "react";
import {
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Switch,
} from "@material-ui/core";
import Page from "components/PageBase";
import { SignOutIcon } from "react-line-awesome";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useDarkMode from "use-dark-mode";

const SettingsPage: React.FC = () => {
  const darkMode = useDarkMode(false);
  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      darkMode.enable();
    } else {
      darkMode.disable();
    }
  };

  return (
    <Page title="Settings">
      <Helmet>
        <title>Admin: Settings</title>
      </Helmet>
      <List subheader={<ListSubheader>Settings</ListSubheader>}>
        <ListItem>
          <ListItemText primary="Dark mode" />
          <ListItemSecondaryAction>
            <Switch checked={darkMode.value} onChange={handleDarkModeChange} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem button component={RouterLink} to="/logout">
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
    </Page>
  );
};

export default SettingsPage;
