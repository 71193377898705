import React, { useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Divider,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { BarsIcon, GearIcon, GamepadIcon } from "react-line-awesome";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthState } from "stores/auth/selectors";
import styles from "./PageBase.module.scss";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

type PageProps = {
  title?: string;
  flexibleSpace?: React.ReactNode;
  children: React.ReactNode;
  p?: number;
  px?: number;
  py?: number;
  pb?: number;
  pt?: number;
  className?: string;
  window?: () => Window;
};

const Page: React.FC<PageProps> = ({
  flexibleSpace,
  title,
  children,
  p,
  px,
  py,
  pb = 5,
  pt,
  className,
  window,
}: PageProps) => {
  const authState = useSelector(selectAuthState);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!authState.isLoggedIn) {
      history.push("/login");
    }
  }, [dispatch, history, authState.isLoggedIn]);

  const handleDrawerOpen = () => {
    // window.scrollTo({ behavior: 'smooth', top: 0 });
    setOpen(!open);
  };

  const menu = (
    <>
      {flexibleSpace && <Divider />}
      {flexibleSpace}
      <Divider />
      <List className={styles.menu}>
        <ListItem button component={RouterLink} to="/">
          <ListItemIcon>
            <GamepadIcon />
          </ListItemIcon>
          <ListItemText primary="Games" />
        </ListItem>
        <ListItem button component={RouterLink} to="/settings">
          <ListItemIcon>
            <GearIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={styles.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className={classes.menuButton}
          >
            <BarsIcon />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={open}
            onClose={handleDrawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {menu}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {menu}
          </Drawer>
        </Hidden>
      </nav>
      <Box className={styles.page}>
        <div className={classes.toolbar} />
        <section className={styles.content}>
          <Box pb={pb} px={px} pt={pt} py={py} p={p} className={className}>
            {children}
          </Box>
        </section>
      </Box>
    </div>
  );
};

export default Page;
