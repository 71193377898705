import React from 'react';
import { Typography } from '@material-ui/core';
import NoAppBarBase from 'components/NoAppBarBase';

const InvalidRoutePage: React.FC = () => {
  return (
    <NoAppBarBase>
      <Typography>404 - This url is invalid</Typography>
    </NoAppBarBase>
  );
};

export default InvalidRoutePage;
