import React, { ChangeEvent } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
} from "@material-ui/core";
import {
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  LockIcon,
} from "react-line-awesome";
import { useHistory } from "react-router-dom";
import NoAppBarBase from "components/NoAppBarBase";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { EMAIL, PASSWORD } from "stores/auth/types";
import styles from "components/NoAppBarBase.module.scss";
import { login } from "stores/auth/slice";
import { AppDispatch } from "stores/store";
import { useDispatch } from "react-redux";
import { MyError } from "stores/types";

interface LoginFormValues {
  email: string;
  password: string;
  remember: boolean;
}

const LoginPage: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies([EMAIL]);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: cookies[EMAIL],
      password: "",
      remember: !!cookies[EMAIL],
    },
  });
  const [state, setState] = React.useState({
    showPassword: false,
  });
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const handleClickShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  const onRememberChanged = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      setValue(PASSWORD, "");
      setValue(EMAIL, "");
      removeCookie(EMAIL, { path: "/" });
    }
  };

  const onSubmit = async (data: LoginFormValues) => {
    if (data.remember) {
      setCookie(EMAIL, data[EMAIL], {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
      });
    }
    const action = await dispatch(
      login({ email: data.email, password: data.password })
    );

    if (login.fulfilled.match(action)) {
      history.push("/");
      return;
    }

    if (login.rejected.match(action)) {
      const error = action.payload as MyError;
      console.log(error);
      setError("password", {
        type: "submit",
        message: "The login credentials are incorrect",
      });
    }
  };

  return (
    <NoAppBarBase>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="email"
          name="email"
          autoComplete="email"
          label="Email"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnvelopeIcon />
              </InputAdornment>
            ),
          }}
          inputRef={register({
            required: true,
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          helperText={
            errors.email &&
            ((errors.email.type === "required" && "Email is required") ||
              (errors.email.type === "pattern" && "Email is invalid"))
          }
          placeholder="you@example.com"
        />
        <TextField
          id="password"
          name="password"
          label="Password"
          autoComplete="password"
          fullWidth
          type={state.showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={register({
            required: true,
            // pattern: /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
          })}
          helperText={
            errors.password &&
            ((errors.password.type === "required" && "Password is required") ||
              (errors.password.type === "pattern" && "Password is invalid") ||
              (errors.password.type === "submit" && errors.password.message))
          }
        />
        <Box
          pt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControlLabel
            label="Remember me"
            control={
              <Checkbox
                name="remember"
                color="primary"
                inputRef={register}
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={onRememberChanged}
                defaultChecked={!!cookies[EMAIL]}
              />
            }
          />
        </Box>
        <Button
          className={styles.submit}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Login
        </Button>
      </form>
    </NoAppBarBase>
  );
};

export default LoginPage;
