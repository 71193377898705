import { combineReducers } from '@reduxjs/toolkit';
import alerterState from 'stores/alerter/slice';
import authState from 'stores/auth/slice';
import gamesState from 'stores/game/slice';

const rootReducer = combineReducers({
  alerterState,
  authState,
  gamesState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
