import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, lightBlue, amber, orange } from '@material-ui/core/colors';
import { dismissAlert } from 'stores/alerter/slice';
import { Button } from "@material-ui/core";
import store from 'stores/store';
import useDarkMode from 'use-dark-mode';
import DayJs from '@date-io/dayjs';
import Alerter from 'components/Alerter';
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import { initAuth } from "stores/auth/slice";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
store.dispatch(initAuth);

const Main: React.FC = () => {
  const darkMode = useDarkMode(false);
  const theme = createMuiTheme({
    palette: {
      type: darkMode.value ? 'dark' : 'light',
      primary: {
        main: darkMode.value ? lightBlue[900] : blue[700],
      },
      secondary: {
        main: darkMode.value ? amber[600] : orange.A700,
      },
    },
  });

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      action={(key: number | string) => (
        <Button onClick={() => store.dispatch(dismissAlert(key))}>
          Dismiss
        </Button>
      )}
    >
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Alerter />
          <App />
        </ThemeProvider>
      </Router>
    </SnackbarProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"
        />
      </Helmet>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DayJs}>
          <Main />
        </MuiPickersUtilsProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
