import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { selectAlerterState } from 'stores/alerter/selectors';
import { removeAlert } from 'stores/alerter/slice';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const visible: { [key: string]: boolean } = {};

const Alerter: React.FC = () => {
  const dispatch = useDispatch();
  const alerterState = useSelector(selectAlerterState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    alerterState.forEach((alert) => {
      const { key, dismissed, severity, duration, message, actions } = alert;
      if (!message) {
        return;
      }
      if (dismissed) {
        closeSnackbar(key);
        return;
      }
      if (visible[key]) {
        return;
      }
      let action;
      if (actions && actions.length) {
        action = actions.map((ac) => (
          <Button component={RouterLink} to={ac.link}>
            {ac.text}
          </Button>
        ));
      }
      enqueueSnackbar(message, {
        key,
        action,
        variant: severity,
        autoHideDuration: duration ?? 8000,
        onExited: (_, k) => {
          dispatch(removeAlert(k));
          delete visible[k];
        },
      });

      visible[key] = true;
    });
  }, [alerterState, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Alerter;
