import React from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  FormLabel,
  Box,
} from "@material-ui/core";
import {
  EyeIcon,
  EyeSlashIcon,
  GamepadIcon,
  LockIcon,
  TrashIcon,
} from "react-line-awesome";
import { useHistory, useLocation } from "react-router-dom";
import Page from "components/PageBase";
import { Controller, useForm } from "react-hook-form";
import styles from "components/PageBase.module.scss";
import { addGame, deleteGame, updateGame } from "stores/game/slice";
import { AppDispatch } from "stores/store";
import { useDispatch } from "react-redux";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MyError } from "stores/types";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import { addAlert } from "stores/alerter/slice";

interface AddGameFormValues {
  name: string;
  password: string;
  repPassword: string;
  startDate: MaterialUiPickersDate;
  startTime: MaterialUiPickersDate;
}

interface AddGamePageParams {
  id: string;
  name: string;
  password: string;
  repPassword: string;
  startAt: number;
}

const AddGamePage: React.FC = () => {
  const location = useLocation<AddGamePageParams>();
  const game = location.state;
  const startAt = dayjs(
    game ? game.startAt : dayjs().add(1, "hour").startOf("hour")
  );
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    errors,
    control,
  } = useForm<AddGameFormValues>({
    defaultValues: {
      name: game?.name,
      password: game?.password,
      repPassword: game?.repPassword,
      startDate: startAt,
      startTime: startAt,
    },
  });
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  const onSubmit = async (data: AddGameFormValues) => {
    const startAt = dayjs(
      data.startDate
        ?.startOf("day")
        .add(data.startTime?.hour() || 0, "hour")
        .add(data.startTime?.minute() || 0, "minute")
    );
    if (!startAt) {
      dispatch(
        addAlert({
          key: "error-invalid-start-date-or-time",
          severity: "error",
          message: "Start date/time is invalid",
        })
      );
      return;
    }
    if (game) {
      const action = await dispatch(
        updateGame({
          id: game.id,
          name: data.name,
          password: data.password,
          repPassword: data.repPassword,
          startAt: startAt.valueOf(),
        })
      );

      if (updateGame.fulfilled.match(action)) {
        history.push("/games");
        return;
      }

      if (updateGame.rejected.match(action)) {
        const error = action.payload as MyError;
        console.log(error);
        dispatch(
          addAlert({
            key: "error-add-game",
            severity: "error",
            message: error.message,
          })
        );
      }
      return;
    }

    const action = await dispatch(
      addGame({
        name: data.name,
        password: data.password,
        repPassword: data.repPassword,
        startAt: startAt.valueOf(),
      })
    );

    if (addGame.fulfilled.match(action)) {
      history.push("/games");
      return;
    }

    if (addGame.rejected.match(action)) {
      const error = action.payload as MyError;
      console.log(error);
      dispatch(
        addAlert({
          key: "error-add-game",
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  const onDeleteClick = async () => {
    const action = await dispatch(deleteGame(game.id));

    if (deleteGame.fulfilled.match(action)) {
      history.push("/games");
      return;
    }

    if (deleteGame.rejected.match(action)) {
      const error = action.payload as MyError;
      console.log(error);
      dispatch(
        addAlert({
          key: "error-delete-game",
          severity: "error",
          message: error.message,
        })
      );
    }
  };

  return (
    <Page p={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="name"
          name="name"
          autoComplete="name"
          label="Name"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GamepadIcon />
              </InputAdornment>
            ),
          }}
          inputRef={register({
            required: true,
          })}
          error={!!errors?.name}
          helperText={
            errors.name && errors.name.type === "required" && "Name is required"
          }
          placeholder="e.g. Chance 123"
        />
        <TextField
          id="password"
          name="password"
          label="Player Password"
          autoComplete="password"
          fullWidth
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={register({
            required: true,
            // pattern: /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
          })}
          error={!!errors?.password}
          helperText={
            errors.password &&
            ((errors.password.type === "required" && "Password is required") ||
              (errors.password.type === "pattern" && "Password is invalid") ||
              (errors.password.type === "submit" && errors.password.message))
          }
        />
        <TextField
          id="repPassword"
          name="repPassword"
          label="MSL Password"
          autoComplete="repPassword"
          fullWidth
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={register({
            required: true,
            // pattern: /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
          })}
          error={!!errors?.repPassword}
          helperText={
            errors.repPassword &&
            ((errors.repPassword.type === "required" && "Password is required") ||
              (errors.repPassword.type === "pattern" && "Password is invalid") ||
              (errors.repPassword.type === "submit" && errors.repPassword.message))
          }
        />
        <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
          <FormControl>
            <FormLabel>Start date</FormLabel>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <DatePicker
                  autoOk
                  variant="static"
                  disablePast
                  views={["date"]}
                  format="ddd Do MMMM YYYY"
                  placeholder={`e.g. ${dayjs().format("DD/MM/YYYY")}`}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  required
                  inputRef={ref}
                  helperText={
                    errors?.startDate?.type === "required" &&
                    "Start date is required"
                  }
                  error={!!errors?.startDate}
                />
              )}
              name="startDate"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Start time</FormLabel>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TimePicker
                  clearable
                  autoOk
                  variant="static"
                  views={["hours", "minutes"]}
                  format="h:mm a"
                  placeholder={`e.g. ${dayjs().format("HH")}`}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  helperText={
                    errors?.startTime?.type === "required" &&
                    "Start time is required"
                  }
                  inputRef={ref}
                  error={!!errors?.startTime}
                />
              )}
              name="startTime"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>
        </Box>
        <Box pt={3}>
          <Button
            className={styles.submit}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {game ? "Save" : "Add"}
          </Button>
        </Box>
        {game && (
          <Box pt={3} textAlign="center">
            <Button
              startIcon={<TrashIcon />}
              variant="text"
              color="secondary"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          </Box>
        )}
      </form>
    </Page>
  );
};

export default AddGamePage;
