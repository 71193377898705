import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert, AlerterState } from './types';

const initialState: AlerterState = [];

const slice = createSlice({
  name: 'alerter',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => [
      ...state,
      action.payload,
    ],
    dismissAlert: (state, action: PayloadAction<string | number>) => [
      ...state.map((error) =>
        error.key === action.payload ? { ...error, dismissed: true } : error,
      ),
    ],
    removeAlert: (state, action: PayloadAction<string | number>) => [
      ...state.filter((error) => error.key !== action.payload),
    ],
  },
});

export const { addAlert, dismissAlert, removeAlert } = slice.actions;

export default slice.reducer;
