export const EMAIL = 'email';
export const PASSWORD = 'password';

export type LoginData = {
  email: string;
  password: string;
};

export type AuthState = {
  isLoggedIn?: boolean;
  isInitialized?: boolean;
  user?: User;
};

export type User = {
  email: string;
  name?: string;
}
