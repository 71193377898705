import copy from 'clipboard-copy';
import { AppDispatch } from 'stores/store';
import { addAlert } from 'stores/alerter/slice';

export const copyToClipboard = (
  dispatch: AppDispatch,
  text?: string,
) => (): void => {
  if (text) {
    copy(text)
      .then(() =>
        dispatch(
          addAlert({
            key: 'copy-to-clipboard',
            severity: 'info',
            duration: 3000,
            message: 'Copied to clipboard',
          }),
        ),
      )
      .catch(() =>
        addAlert({
          key: 'copy-to-clipboard',
          severity: 'warning',
          duration: 2000,
          message: "Couldn't write to clipboard",
        }),
      );
  }
};
