import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG || "{}"
);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const secretsCol = firebase.firestore().collection("secrets");
export const gamesCol = firebase.firestore().collection("games");
