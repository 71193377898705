import React from 'react';
import { Box } from '@material-ui/core';
import styles from './NoAppBarBase.module.scss';

type NoAppBarBaseProps = {
  children?: React.ReactNode;
};

const NoAppBarBase: React.FC<NoAppBarBaseProps> = ({
  children,
}: NoAppBarBaseProps) => (
  <div className={styles.root}>
    <Box className={styles.content}>{children}</Box>
  </div>
);

export default NoAppBarBase;
